import { string } from "yup";
import type { AnySchema } from "yup/lib/schema";
import type { InputType } from "~/types/form";

const mapping: Record<string, AnySchema | string> = {
  text: string().required(messageRequired),
  password: string().required(messageRequired),
  email: string().required(messageRequired).email(messageEmail),
  tel: "phoneRule",
  select: "dropdownRule",
  date: string().required(messageRequired),
};

export const validate = (type?: InputType, required?: boolean) => {
  if (required && type) {
    return mapping[type];
  }
};
